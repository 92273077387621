import { AfterContentInit, Component, ContentChild, Input } from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'ts-form-controls-card',
  templateUrl: './form-controls-card.component.html',
  styleUrls: ['./form-controls-card.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class FormControlsCardComponent {

  @Input() label = '';
  @Input() isExpandable = false;

  @Input() isEmpty = false;
  @Input() emptyIcon?: string; // for example: ts-icon-goods-box
  @Input() emptyText = '';

  isExpanded = true;

}
