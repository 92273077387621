import {CommonModule, NgOptimizedImage} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommentComponent } from './comment/comment.component';
import { TsEntityCommentListComponent } from './comments-block.component';
import { ToastrModule } from 'ngx-toastr';
import {AttachedFileComponent} from "@topseller/common/attached-file/attached-file.component";
import {BytesToMegabytesPipe} from "@topseller/ui/pipes/bytes-to-megabytes/bytes-to-megabytes.pipe";
import {TsDropdownModule} from "@topseller/ui/dropdown";
import {TsScrollModule} from "@topseller/ui/scroll";
import { InputCommentComponent } from "@topseller/common/input-comment";

@NgModule({
  declarations: [TsEntityCommentListComponent, CommentComponent],
  imports: [CommonModule, FormsModule, ToastrModule, ReactiveFormsModule, InputCommentComponent, AttachedFileComponent, BytesToMegabytesPipe, NgOptimizedImage, TsDropdownModule, TsScrollModule],
  exports: [TsEntityCommentListComponent],
  providers: [],
})
export class TsEntityCommentModule {}
