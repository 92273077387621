import { TotalCalculationInput, TotalLine } from "../interfaces";
import { Product, Vat } from "../../data";
import { DocumentItemsTableTotalService } from "./document-items-table-total.service";
import { Injectable } from "@angular/core";
import { calculatePercent, calculateVat } from "../utils/calculate-vat";

@Injectable()
export class TradeDocumentProductsTotalService implements DocumentItemsTableTotalService<Product> {
  getTotals(value: TotalCalculationInput<Product>): TotalLine[] {
    const {total,totalWithoutVat,vat } = this.calculateTotalsVats(value.items, value.vatIncluded!, value.isVat!);
    return [
      {
        title: 'Сумма без НДС',
        value: totalWithoutVat,
        visible: value.isVat,
        currencyFormat: true,
      },
      { title: 'Сумма НДС', value: vat, visible: value.isVat, currencyFormat: true, },
      { title: 'Сумма документа', value: total, visible: true, currencyFormat: true, },
    ].filter((item) => item.visible);
  }

  protected calculateTotalsVats = (
    value: any,
    vatIncluded: boolean,
    isVat: boolean
  ): { total: number; vat: number; totalWithoutVat: 0 } => {
    return value.reduce(
      (
        acc: { total: number; vat: number; totalWithoutVat: number },
        curr: {
          quantity: number;
          price: number;
          vatType: Vat;
          discountPricePercent: number;
        }
      ) => {
        const {total, vat, totalWithoutVat} = acc;
        const totalLine = calculatePercent(curr);
        const totalWithoutPercent = curr.quantity * curr.price;
        const vatLine = isVat ? this.calculateVat(curr, vatIncluded, isVat) : 0;

        return {
          total: total + totalLine + (vatIncluded ? 0 : vatLine),
          vat: vat + vatLine,
          totalWithoutVat:
            totalWithoutVat + totalWithoutPercent - (vatIncluded ? vatLine : 0),
        };
      },
      {total: 0, vat: 0, totalWithoutVat: 0}
    );
  };

  private calculateVat(value: {
                         price: number;
                         quantity: number;
                         vatType: Vat;
                       }, vatIncluded: boolean,
                       isVat: boolean): number {
    const {price, quantity, vatType} = value;
    if (!isVat || !vatType?.vatValue) {
      return 0;
    }
    return calculateVat(price, quantity, vatType, vatIncluded);
  }
}
