import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ts-autoresize-textarea]'
})
export class TsTextareaAutoresizeDirective implements OnInit {

  private maxHeight: number = 70;

  constructor(private elementRef: ElementRef) {
  }

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  ngOnInit() {

    const style = getComputedStyle(this.elementRef.nativeElement);
    const maxHeightFromCss = parseInt(style.maxHeight, 10);

    if (!isNaN(maxHeightFromCss)) {
      this.maxHeight = maxHeightFromCss;
    }

    const textarea = this.elementRef.nativeElement;
    if (textarea.scrollHeight && textarea.value) {
      setTimeout(() => this.resize());
    } else {
      textarea.style.height = 'auto';
    }
  }


  resize() {
    const textarea = this.elementRef.nativeElement;
    textarea.style.height = 'auto';
    const newHeight = Math.min(textarea.scrollHeight, this.maxHeight);
    textarea.style.height = `${newHeight}px`;
    textarea.style.overflowY = newHeight >= this.maxHeight ? 'auto' : 'hidden';
  }
}
