import { ChangeDetectorRef } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, take } from 'rxjs';

// TODO: удалить когда будет перерабатываться эта часть
import { Tab, TAB_LIST } from '../../pages/sales/sales-item/sales-item.mock';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { Store } from "@ngrx/store";
import { setShowLoader } from '@topseller/core';

type CreationState = 'loading' | 'error' | 'success' | null;

export class BaseItemInfoComponent {
  public showHeaderBtns$ = new BehaviorSubject<boolean>(false);
  public itemId$ = new BehaviorSubject<string>('');

  tabs: Tab[] = TAB_LIST;

  public backLinkUrl!: string;

  public isDeletedState = false;

  public onlyNumberTemplate = '0*';
  public telTemplate = '0 (000) 000-00-00 ';

  public innFill$ = new BehaviorSubject<boolean>(false);
  public innLoading$ = new BehaviorSubject<boolean>(false);

  public showDeleteConfirmModal$ = new BehaviorSubject<boolean>(false);

  public showDialog = false;
  public subjectConfirm = new Subject<string | boolean>();

  public isNavigationEnable = true;
  public creationState$ = new BehaviorSubject<CreationState>(null);

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected toastr: ToastrService,
    protected router: Router,
    protected changeDetectorRef: ChangeDetectorRef,
    protected matDialog: MatDialog,
    protected store: Store
  ) {
  }

  getResolvedData(form: FormGroup, item: string): void {
    this.activatedRoute.data.subscribe((data) => {
      if (!data[item]) {
        this.showHeaderBtns$.next(false);
      } else {
        this.itemId$.next(data[item].id);
        this.showHeaderBtns$.next(true);

        for (const key in data[item]) {
          form.controls[key] && form.controls[key].setValue(data[item][key]);
        }
      }
    });
  }

  createItem(
    form: FormGroup,
    service: any,
    createMethodName: string,
    itemName: string,
    url: string,
    settlementForm?: FormGroup
  ): any {
    if (form.valid) {
      this.creationState$.next('loading');

      service[createMethodName](form.value).subscribe({
        next: (result: any) => {
          this.toastr.success(itemName + ' успешно создан');
          this.creationState$.next('success');
          this.store.dispatch(setShowLoader({showLoader: false}))

          form.markAsPristine();
          settlementForm?.markAsPristine();

          if (!this.isNavigationEnable) {
            this.navigateByUrl(url);
          }

          if (this.isNavigationEnable && result && result.id) {
            this.navigateByUrl(`${url}/${result.id}`);
          }
        },
        error: (err: any) => {
          this.creationState$.next('error');
          this.store.dispatch(setShowLoader({ showLoader: false }))

          this.toastr.error(
            err && err.error
              ? err.errors && err.errors.length && err.errors[0].message
                ? err.errors[0].message
                : err.error
              : 'Что-то пошло не так'
          );
        },
      });
    } else {
      this.toastr.error('Форма содержит ошибки');
    }
  }

  updateItem(
    form: FormGroup,
    service: any,
    updateMethodName: string,
    itemName: string,
    settlementForm?: FormGroup
  ): void {
    this.itemId$.pipe(take(1)).subscribe((id) => {
      if (form.valid) {
        service[updateMethodName]({
          ...form.value,
          id,
        }).subscribe({
          next: () => {
            this.toastr.success(itemName + ' успешно сохранен');
            this.store.dispatch(setShowLoader({ showLoader: false }))
            form.markAsPristine();
            settlementForm?.markAsPristine();
          },
          error: (err: any) => {
            this.store.dispatch(setShowLoader({ showLoader: false }))
            this.toastr.error(
              err && err.error
                ? err.errors && err.errors.length && err.errors[0].message
                  ? err.errors[0].message
                  : err.error
                : 'Что-то пошло не так'
            );
          },
        });
      } else {
        this.toastr.error('Форма содержит ошибки');
      }
    });
  }

  deleteItem(
    service: any,
    deleteMethodName: string,
    url: string,
    confirmState: string
  ): void {
    if (confirmState === 'да') {
      this.itemId$.pipe(take(1)).subscribe((id) => {
        service[deleteMethodName](id).subscribe({
          next:()=>{
          this.isDeletedState = true;
          this.toastr.success('Удаление прошло успешно');
          this.navigateByUrl(url);
        },
          error: (err: any) => {
            this.handleApiError(err);
          },
        });
      });
    } else {
      this.isDeletedState = false;
      this.showDeleteConfirmModal$.next(false);
    }
  }

  detectChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  navigateByUrl(
    url: string = '',
    form?: FormGroup,
    initialValues?: object,
    settlementForm?: FormGroup
  ) {
    this.router.navigateByUrl(url);

    if (url.includes('create') && form && initialValues) {
      form.reset(initialValues);

      if (settlementForm) {
        while (
          (settlementForm?.get('settlementAccounts') as FormArray).length !== 0
          ) {
          (settlementForm?.get('settlementAccounts') as FormArray).removeAt(0);
        }

        settlementForm.markAsPristine();
      }
    }
  }

  onDeactivateSelection($event: any) {
    this.showDialog = false;
    this.subjectConfirm.next($event);
  }

  openConfirmDialog() {
    return this.matDialog.open(ConfirmDialogComponent);
  }

  protected handleApiError(err: any) {
    console.log(err);
    this.store.dispatch(setShowLoader({showLoader: false}));
    this.toastr.error(
      err?.errors?.length && err.errors[0].message
        ? err.errors[0].message
        : err?.message || 'Что-то пошло не так'
    );
  }
}
