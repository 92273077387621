import { Directive } from '@angular/core';
import { TsDataEntity } from '@topseller/core';
import { BaseListComponent, toFilterString } from '../base-list/index';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TableSettingsService } from '../table/table-settings-service';
import { distinctUntilChanged, map, of, switchMap } from 'rxjs';

@Directive()
export abstract class BaseTableWithSortComponent<
  T extends TsDataEntity
> extends BaseListComponent<T> {
  protected state: Record<string, undefined>;

  protected constructor(
    route: ActivatedRoute,
    protected router: Router,
    protected tableSettingsService?: TableSettingsService
  ) {
    super(route);

    this.state = this.router.getCurrentNavigation()?.extras.state || {};
  }

  override initializeCurrentFilter(): void {
    this.currentFilter$ = this.route.queryParams.pipe(
      switchMap((params: Params) => {
        if (
          this.tableSettingsService &&
          this.tableIdentifier &&
          (!params['sortName'] || !params['sortDir'])
        ) {
          // Получаем дефолтную сортировку, если tableIdentifier задан и в URL нет параметров сортировки
          return this.tableSettingsService
            .getDefaultSort(this.tableIdentifier)
            .pipe(
              switchMap(({ columnKey, sortDirection }) => {
                // Добавляем параметры дефолтной сортировки к URL
                const updatedParams = {
                  ...params,
                  sortName: columnKey,
                  sortDir: sortDirection,
                };
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: updatedParams,
                  queryParamsHandling: 'merge', // Объединяем с существующими параметрами
                  state: this.state,
                });
                return of(updatedParams); // Продолжаем с обновленными параметрами
              })
            );
        }
        return of(params); // Продолжаем с текущими параметрами, если они уже включают сортировку
      }),
      map((params: Params) => {
        const filter = this.extractFilter(params);
        const filterString = toFilterString(filter);
        const { page, search = '', sortName, sortDir, limit } = params;
        return {
          page: page ? page - 1 : 0,
          search,
          filter,
          filterString,
          sortName,
          sortDir,
          limit,
        };
      }),
      distinctUntilChanged()
    );
  }
}
