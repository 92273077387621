import { createSelector } from '@ngrx/store';

import { FullUser } from '../../model';

export const selectFeature = (state: any) => state.auth;

export const selectIsLoggedIn = createSelector(
  selectFeature,
  (state) => state.isLoggedIn
);

export const selectIsActive = createSelector(
  selectFeature,
  (state) => state.isSignedUp
);

export const selectUser = createSelector(selectFeature, (state) => state.user);

export const selectEmployee = createSelector(selectFeature, (state) => state.employee);

export const selectAccount = createSelector(selectFeature, (state) => state.account);

export const selectActive = createSelector(
  selectFeature,
  (state) => state.active
);

export const selectSettingsTableColumns = (entity: string) => createSelector(
  selectFeature,
  (state) => state.settings[entity]
);

export const selectShowHelpOnStart = createSelector(
  selectFeature,
  (state) => state.settings?.showHelpOnStart
);

export const selectWasShownHelpOnStart = createSelector(
  selectFeature,
  (state) => state.wasShownHelpOnStart
);

export const selectUserLimits = createSelector(
  selectUser,
  (user: FullUser) => user.limits
);

export const selectItems = createSelector(
  selectFeature,
  (state) => state.items
);

export const selectIndicators = createSelector(
  selectFeature,
  (state) => state.settings['hub_interface_dashboard_indicators']
)
