<div class="p-3">
  <div class="d-flex justify-content-between align-items-center">
    <h5 class="ts-subtitle-strong m-0">{{ title }}</h5>
    <span (click)="selected(false)"
          class="ts-icon ts-icon-actions-Close cursor-pointer ts-icon-medium"></span>
  </div>
  <div class="dialog__body ts-subtitle mt-2 ts-text-secondary">{{ content }}</div>
  <div class="d-flex gap-2 mt-3 align-items-center">
    <button class="flex-1 ts-btn primary ts-btn-filled ts-btn-md" (click)="selected(false)">
      {{ cancelBtn }}
    </button>
    <button class="flex-1 ts-btn primary ts-btn-bezeled ts-btn-md" (click)="selected(true)">
      {{ okBtn }}
    </button>
  </div>
</div>
