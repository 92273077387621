import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY, map, Observable, of, switchMap, take, takeUntil, } from 'rxjs';
import { AbstractDocument } from "./document-base";
import { CatalogsDataService, DocumentUntrashService, ItemDataService } from "./services";
import { HubState, selectStatus } from "../store";
import { DocumentCreationLink } from "./interfaces";
import { HubDocument, Status, Vat, } from "../data";
import { ConfirmDialogComponent, setShowLoader } from "@topseller/core";
import { ActionItem } from "@topseller/ui";
import { Location } from "@angular/common";
import { openCustomFieldsModal } from "./components/custom-fields-modal";
import { loadLinkedDocuments } from "./components/linked-documents/store";

@Directive()
export abstract class DocumentEditBase<T extends HubDocument>
  extends AbstractDocument
  implements OnInit {

  public item!: T;

  public vatList!: Vat[];
  public backLinkUrl!: string;
  public abstract override form: FormGroup;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public statusList$: Observable<Status[]> = of([])

  public abstract documentDeleteRole:string;
  public abstract documentEditRole:string;

  public documentActionsList: ActionItem[] = [];

  public documentCreationLinks: DocumentCreationLink[] = [];

  public get isEdit(): boolean {
    return this.id != null && !this.isTrashed;
  }

  protected referrer: string | undefined;

  protected hasLinkedDocuments = false;

  protected constructor(
    catalogsDataService: CatalogsDataService,
    store: Store<HubState>,
    protected activatedRoute: ActivatedRoute,
    protected itemService: DocumentUntrashService<T>,
    protected itemDataService: ItemDataService<T>,
    protected toastrService: ToastrService,
    protected dialog: MatDialog,
    protected router: Router,
    protected location: Location,
    protected changeDetectorRef: ChangeDetectorRef,
  ) {
    super(catalogsDataService, store);
    this.referrer = (location.getState() as { referrer?: string })['referrer'];


  }

  public get isTrashed(): boolean {
    return !!this.form.get('isTrashed')?.value;
  }

  public get getParentId(): string {
    return this.item?.entityId ?? this.item.id;
  }

  public openEditFieldsModal(): void {
    this.store.dispatch(openCustomFieldsModal({entity: this.entityName}));
  }



  protected generateCreationUrls() {
    this.documentCreationLinks = [];
  }

  protected abstract initForm(): void;

  protected abstract setFormValue(): void;

  public override ngOnInit(): void {
    this.documentActionsList = [{
      title: `Удалить`,
      action: () => {
        this.delete();
      },
      requiredRole: this.documentDeleteRole
    }];

    super.ngOnInit();

    this.statusList$ = this.store.select(
      selectStatus(this.entityName)
    );


    this.initItem();
    this.initForm();

    // безусловно выставляем статус, при заполнении формы это поле обновиться
    this.statusList$.pipe(take(1)).subscribe((statusList: Status[]) => {
      this.form.patchValue({status: statusList[0]});
    });

    this.setFormValue();
    this.generateCreationUrls();
    this.isLoading$.next(false);

    if(this.isTrashed) {
      this.form.disable();
    }
  }

  public delete(): void {
    const id = this.form.get('id')?.value;

    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: {
          title: 'Удаление документа',
          content: 'Вы уверены что хотите удалить этот документ?',
          okBtn: 'Удалить',
        },
        backdropClass: 'ts-modal-backdrop',
        panelClass: ['ts-modal-panel','ts-modal-panel-lg' ],
        width: '470px',
        id: 'ts-modal',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((result: boolean) => {
          if(result) {
            this.form.markAsPristine();
          }
          return result ? this.itemDataService.delete(id as string) : EMPTY;
        })
      )
      .subscribe({
        next: () => {
          this.toastrService.success('Документ удален');
          this.router.navigate([this.backLinkUrl]);
        },
        error: (err: any) => {
          this.handleApiError(err);
        },
      });
  }

  public save(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.toastrService.error('Форма содержит ошибки');
      return;
    }
    this.store.dispatch(setShowLoader({showLoader: true}));
    const formValue = this.form.value;
    const request$: Observable<T> = this.form.get('id')?.value
      ? this.itemDataService.update({
        ...(formValue)
      })
      : this.itemDataService.create(formValue);

    request$.pipe(take(1)).subscribe({
      next: (data: any) => {
        this.store.dispatch(setShowLoader({showLoader: false}));
        this.toastrService.success('Документ сохранен');

        if (formValue.id && this.hasLinkedDocuments) {
          this.updateLinkedDocuments(formValue.id);
        }

        this.form.markAsPristine();
        this.item = data;
        this.form.patchValue({
          id: data.id || formValue.id,
          number: data.number,
          datedAt: data.datedAt,
        });
        this.router.navigate([`../${data.id || formValue.id}`], {
          relativeTo: this.activatedRoute,
        });
      },
      error: (err: any) => {
        this.handleApiError(err);
      },
    });
  }

  protected updateLinkedDocuments(id: string): void {
    this.store.dispatch(
      loadLinkedDocuments({
        entity: this.entityName,
        id,
      })
    );
  }

  public saveOrRestore(): void {
    if (this.isTrashed) {
      this.restore();
    } else {
      this.save();
    }
  }

  protected initItem(): void {
    this.item = this.activatedRoute.snapshot.data[this.entityName] || {};
  }

  public restore(): void {
    if (this.form.value.id) {
      this.store.dispatch(setShowLoader({showLoader: true}));
      this.itemService
        .getUntrash(this.form.get('id')?.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res: T) => {
            this.store.dispatch(setShowLoader({showLoader: false}));
            this.form.patchValue(res);
            const {value: formValue} = this.form;
            this.router.navigate([`../${formValue.id}`], {
              relativeTo: this.activatedRoute,
            });
            this.toastrService.success(
              'Документ успешно восстановлен',
              'Успех'
            );

            this.form.get('isTrashed')?.setValue(false);
            this.form.enable();
            this.changeDetectorRef.detectChanges();
          },

          error: (err: any) => {
            this.handleApiError(err);
          },
        });
    }
  }

  protected handleApiError(err: any) {
    console.log(err);
    this.store.dispatch(setShowLoader({showLoader: false}));
    this.toastrService.error(
      err?.errors?.length && err.errors[0].message
        ? err.errors[0].message
        : err?.message || 'Что-то пошло не так'
    );
  }


}
