import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  content: string;
  okBtn?: string;
  cancelBtn?: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  public get okBtn() {
    return this.data?.okBtn || 'Да';
  }
  public get cancelBtn() {
    return this.data?.cancelBtn || 'Отмена';
  }
  public get title() {
    return this.data.title;
  }
  public get content() {
    return this.data.content;
  }

  constructor(
    private matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  selected(value: boolean): void {
    this.matDialogRef.close(value);
  }
}
