/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 23.0920.0717
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountSelectResponseItems, BillingAccountRestrictions, BillingSubscription, Configuration } from "../model";
import { Observable } from 'rxjs';

import { BASE_PATH } from "../tokens";


@Injectable({providedIn: "root"})
export class SecurityService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'http://denis.dev.topseller.ru';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getSecurityRestrictions(productId: string): Observable<BillingAccountRestrictions> {
    return this.httpClient.get<BillingAccountRestrictions>(`${this.basePath}security/restrictions/${productId}`);
  }

  getSubscriptions(): Observable<BillingSubscription[]> {
    return this.httpClient.get<BillingSubscription[]>(`${this.basePath}security/subscription`);
  }

  public getUserAccounts(): Observable<AccountSelectResponseItems[]> {
    return this.httpClient.request<AccountSelectResponseItems[]>(
      'get',
      `${this.basePath}security/accounts`,
    );
  }

  public patchAccountDeactivatedPayments(accountId: string, body?: BillingSubscription): Observable<any> {

    if (accountId === null || accountId === undefined) {
      throw new Error('Required parameter accountId was null or undefined when calling patchAppV1AccountDeactivaterecurringpayments.');
    }


    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<any>('patch',`${this.basePath}accounts/${accountId}/subscriptions/nonrecurring`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers
      }
    );
  }

}
