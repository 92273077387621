<div class="ts-table__row ts-empty-row">
  <div
    class="cell cell-empty cell-empty__select"
    [ngStyle]="{
      'flex-basis': +tableHeaders[1]!.width! + firstColumnWidth + 'px',
      width: +tableHeaders[1]!.width! + firstColumnWidth + 'px'
    }"
    [tsTableCell]="tableHeaders[1].key"
    [widthOffset]="firstColumnWidth"
  >
    <div class="ts-content">
      <ts-select
        size="cell"
        placeholder="Выберите товар"
        [identityMatcher]="recordIdentityMatcher"
        [stringify]="recordStringify"
        [formControl]="selectedProductControl"
      >
        <ts-entity-list
          [queryFn]="productQueryFn"
          [itemTemplate]="productItem"
        ></ts-entity-list>
      </ts-select>
    </div>
  </div>
  <div
    class="cell"
    *ngFor="let header of tableHeaders | slice : 2"
    [tsTableCell]="header.key"
    [ngStyle]="{
      'flex-basis': header.width + 'px',
      width: header.width + 'px'
    }"
  >
    <div class="ts-content"></div>
  </div>
</div>

<ng-template #productItem let-item>
  <ts-product-list-item [item]="item"></ts-product-list-item>
</ng-template>
